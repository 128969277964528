<template>
	<div class="mform-inner edit_inner">
		<div class="mform-logo"></div>
		<div class="mform-head" v-if="globalProperties.formHeader.isShow">
			<div v-if="globalProperties.formHeader.type === '文字'" class="txt" key="mFormHeadText">{{globalProperties.formHeader.textCont}}</div>
			<div v-else class="img" key="mFormHeadImg">
				<img v-if="globalProperties.formHeader.imgSrc" :src="globalProperties.formHeader.imgSrc">
			</div>
		</div>
		<div class="medit-head">
			<div class="mform-title" v-if="globalProperties.formTitle.isShow">{{globalProperties.formTitle.textCont}}</div>
			<div class="mform-descript" v-if="globalProperties.formDescript.isShow">{{globalProperties.formDescript.textCont}}</div>
		</div>
		<div class="mform-widget">
			<div class="mform-list">
				<div class="mform-item" v-for="(item,index) in list" :key="index">
					<div class="mform-sub" v-for="(o,i) in item.children" :key="i">
						<mWidget :detial="o" :cusStyle="mGlobalStyle" />
						<div class="cover" v-show="JSON.stringify(astrictControl)=='{}'||formDataId"></div>		
					</div>
				</div>
			</div>
		</div>
		<el-form :model="form" label-width="80px" class="yzm" v-if="verifyType == 1">
			<el-form-item label="验证码" prop="gifCode" :rules='[{ required:true,message:"验证码不能为空", trigger: "blur" }]'>
				<el-input v-model="form.gifCode"></el-input>
			</el-form-item>
			<el-form-item>
				<el-image :src="verificationCode" @click="getGifCode"></el-image>
			</el-form-item>
		</el-form>
		<div class=" msubmit_btn">
			<el-button type="primary" @click="formSubmit" v-if="logicFlag && !formDataId">{{globalStyle.submitBtnStyle.text}}</el-button>
			<el-button type="primary" v-else>{{globalStyle.submitBtnStyle.text}}</el-button>
		</div>
	</div>
</template>
<script>
import $ from "jquery";
import { get,post,getGifCode } from "/src/services/ajax_ucenter.js";
import mWidget from "@/components/mWidget";
export default {
	components: {
		mWidget,
	},
	props:{
		logicFlag:{
			type:Boolean
		},
		astrictControl:{
			type:Object
		}
	},
	data() {
		return {
			sizeRatio: 1,
			formToken : sessionStorage.getItem('token')?sessionStorage.getItem('token'):'',
			identityType:sessionStorage.getItem('identityType')?sessionStorage.getItem('identityType'):'',
			formId:'',
			email:'',
			formDataId:'',
			userId:'',
			verifyType:0,
			verificationCode:'',
			form:{
				gifCode:''
			},
			urlType:0,
			globalStyle: {
				themeColor: '',
				formWidth: '',
				contStyle: {},
				itemStyle: {},
				labelStyle: {},
				submitBtnStyle: {}
			},
			globalProperties: {
				formHeader: {},
				formBg: {},
				formTitle: {},
				formDescript: {}
			},
			mGlobalStyle: {
				formHeader: {},
				formBg: {},
				formTitle: {},
				formDescript: {},
				contStyle: {},
				itemStyle: {},
				labelStyle: {},
				inputStyle: {},
				submitBtnStyle: {}
			},
			list: [],
			canAnswer: 1,
			cantMessage: '',
			startPassword: 0,
			password: '',
			userInputPsd: ''
		}
	},
	mounted(){
		this.identityType = this.$route.query.identityType?this.$route.query.identityType:sessionStorage.getItem('identityType');
		this.formId = this.$route.query.formId?this.$route.query.formId:sessionStorage.getItem('formId')?sessionStorage.getItem('formId'):'';
		this.formDataId = this.$route.query.dataId?this.$route.query.dataId:'';
		this.email = this.$route.query.email?this.$route.query.email:'';
		this.userId = this.$route.query.userId?this.$route.query.userId:'';
		if(this.identityType == 'admin'){
			if(this.userId){
				this.getTplFormData(this.formId); //超管后台查看用户发布到答题广场的表单
			}else{
				this.getFormTpl(); //超管后台查看模板
			}
		}else{
			if(this.userId){
				this.getTplFormPreview(this.formId,this.userId)
			}else if(this.formDataId){
				this.getUserFormData(this.formId,this.formDataId);
			}else{
				if(this.logicFlag){
					this.getFormData();
				}else{
					this.getTplFormData(this.formId);
				}
			}
		}
	},
	methods: {
		// 获取模板表单数据
		getTplFormData(formId){
			const _this = this;
			get('/formadmin/formmodel/from_preview.jhtml',{formId}).then(res => {
				if(res.code == 200){
					let formObj = JSON.parse(res.data.jsonDate);
					_this.list = formObj.list;
					_this.globalStyle = res.data.pcStyle?res.data.pcStyle:formObj.globalStyle;
					_this.mGlobalStyle = res.data.mobileStyle?res.data.mobileStyle:formObj.mGlobalStyle;
					_this.globalProperties = {
						formHeader:_this.globalStyle.formHeader,
						formBg:_this.globalStyle.formBg,
						formTitle:_this.globalStyle.formTitle,
						formDescript:_this.globalStyle.formDescript,
					};
					_this.loadStyle();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 详情页获取模板表单数据
		getFormData(){
			const _this = this;
			get('/formadmin/formmodel/from_data_add.jhtml',_this.astrictControl).then(res => {
				if(res.code == 200){
					let formObj = JSON.parse(res.data.formObj);
					_this.list = formObj.list;
					_this.globalStyle = res.data.pcStyle?res.data.pcStyle:formObj.globalStyle;
					_this.mGlobalStyle = res.data.mobileStyle?res.data.mobileStyle:formObj.mGlobalStyle;
					_this.globalProperties = {
						formHeader:_this.globalStyle.formHeader,
						formBg:_this.globalStyle.formBg,
						formTitle:_this.globalStyle.formTitle,
						formDescript:_this.globalStyle.formDescript,
					};
					_this.list.forEach(item=>{
						if(item.children){
							item.children.forEach(o=>{
								o.itemStyle = _this.globalStyle.itemStyle;
								o.labelStyle = _this.globalStyle.labelStyle;
							})
						}
					})
					_this.verifyType = res.data.verifyType?res.data.verifyType:0;
					if(_this.verifyType == 1){
						_this.getGifCode()
					}
					_this.urlType = res.data.urlType?res.data.urlType:0;
					_this.loadStyle();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		getGifCode(){
			const _this = this;
			getGifCode('/com/gifCode.jhtml',{rand:'form'}).then(res => {
				_this.verificationCode = 'data:image/png;base64,' + btoa(new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), ''))
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 获取用户表单数据
		getUserFormData(formId,formDataId){
			const _this = this;
			get('/formadmin/formmodel/from_data_detail.jhtml',{formId,formDataId}).then(res => {
				if(res.code == 200){
					_this.logicFlag = true;
					let formObj = JSON.parse(res.data.formDataObj);
					_this.list= formObj.quesDate;

					console.log(formObj)
					_this.globalStyle = formObj.pcStyle;
					_this.globalProperties = {
						formHeader:_this.globalStyle.formHeader,
						formBg:_this.globalStyle.formBg,
						formTitle:_this.globalStyle.formTitle,
						formDescript:_this.globalStyle.formDescript,
					};
					_this.mGlobalStyle = formObj.mobileStyle;
					_this.loadStyle();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 获取模板中心表单详情
		getFormTpl(){
			const _this = this;
			let param = {
				formId:_this.formId,
			}
			get('/superadm/formmodel/show.do',param).then(res => {
				if(res.code == 200){
					let formObj = JSON.parse(res.data.formObj);console.log(formObj)
					_this.list = formObj.list;
					_this.globalStyle = formObj.pcStyle?JSON.parse(formObj.pcStyle):formObj.globalStyle;
					_this.mGlobalStyle = formObj.mobileStyle?JSON.parse(formObj.mobileStyle):formObj.mGlobalStyle;
					_this.globalProperties = {
						formHeader:_this.globalStyle.formHeader,
						formBg:_this.globalStyle.formBg,
						formTitle:_this.globalStyle.formTitle,
						formDescript:_this.globalStyle.formDescript,
					};
					_this.list.forEach(item=>{
						if(item.children){
							item.children.forEach(o=>{
								o.itemStyle = _this.globalStyle.itemStyle;
								o.labelStyle = _this.globalStyle.labelStyle;
							})
						}
					})
					
					_this.loadStyle();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 模板中心预览获取模板表单数据
		getTplFormPreview(formId){
			const _this = this;
			get('/formadmin/superAdm_preview.jhtml',{formId}).then(res => {
				if(res.code == 200){
					let formObj = JSON.parse(res.data.jsonDate);
					console.log(formObj)
					_this.list = formObj.list;
					_this.globalStyle = formObj.pcStyle?JSON.parse(formObj.pcStyle):formObj.globalStyle;
					_this.mGlobalStyle = formObj.mobileStyle?JSON.parse(formObj.mobileStyle):formObj.mGlobalStyle;
					_this.globalProperties = {
						formHeader:_this.globalStyle.formHeader,
						formBg:_this.globalStyle.formBg,
						formTitle:_this.globalStyle.formTitle,
						formDescript:_this.globalStyle.formDescript,
					};
					_this.list.forEach(item=>{
						if(item.children){
							item.children.forEach(o=>{
								o.itemStyle = _this.globalStyle.itemStyle;
								o.labelStyle = _this.globalStyle.labelStyle;
							})
						}
					})
					_this.loadStyle();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 加载样式
		loadStyle(){
			this.$nextTick(function () {
				const mFormHeadObj = this.mGlobalStyle.formHeader.textStyle;
				$(".mform-head .txt").css({
					fontSize: mFormHeadObj.fontSize + "px",
					color: mFormHeadObj.color,
					fontWeight: mFormHeadObj.fontWight,
					fontStyle: mFormHeadObj.fontStyle,
					textDecoration: mFormHeadObj.textDecoration,
					textAlign: mFormHeadObj.textAlign == "居左"? "left":mFormHeadObj.textAlign == "居中"?"center":"right",
					backgroundColor: mFormHeadObj.backgroundColor,
					padding: mFormHeadObj.paddingVertical + "px " + mFormHeadObj.paddingHorizontal + "px",
				});
				$(".mform-head .img img").css({
					height: this.mGlobalStyle.formHeader.imgHeight + 'px'
				})
				const mFormTitleObj = this.mGlobalStyle.formTitle.textStyle;
				let mFormTitleBg = mFormTitleObj.bgType == "默认"? "unset":mFormTitleObj.bgType == "颜色"? mFormTitleObj.backgroundColor:"url(" + mFormTitleObj.backgroundImage + ")";
				$(".mform-title").css({
					fontSize: mFormTitleObj.fontSize + "px",
					color: mFormTitleObj.color,
					fontWeight: mFormTitleObj.fontWight,
					fontStyle: mFormTitleObj.fontStyle,
					textDecoration: mFormTitleObj.textDecoration,
					padding: mFormTitleObj.paddingTop + "px " + mFormTitleObj.paddingHorizontal + "px " + mFormTitleObj.paddingBottom + 'px',
					background: mFormTitleBg,
					backgroundSize: 'cover',
					backgroundPosition: 'center'
				})
				const mFormDescriptObj = this.mGlobalStyle.formDescript.textStyle;
				$(".mform-descript").css({
					fontSize: mFormDescriptObj.fontSize + "px",
					color: mFormDescriptObj.color,
					fontWeight: mFormDescriptObj.fontWight,
					fontStyle: mFormDescriptObj.fontStyle,
					textDecoration: mFormDescriptObj.textDecoration,
					padding: mFormDescriptObj.paddingVertical + "px " + mFormDescriptObj.paddingHorizontal + "px",
				});
				const mFormContObj = this.mGlobalStyle.contStyle;
				$(".mform-widget").css({
					padding: mFormContObj.paddingTop + "px " + mFormContObj.paddingHorizontal + "px " + mFormContObj.paddingBottom + 'px',
				});
				const mFormBgObj = this.mGlobalStyle.formBg;
				let mFormBg = '';
				if(mFormBgObj.isShow){
					if(mFormBgObj.type == '颜色'){
						mFormBg = mFormBgObj.color;
					}else{
						if(mFormBgObj.imgSrc){
							mFormBg = 'url(' + mFormBgObj.imgSrc + ')';
						}
					}
				}else{
					mFormBg = '#fff';
				}
				$(".mform-inner").css({
					background: mFormBg,
					backgroundSize: 'cover'
				});
				const mSubmitBtnObj = this.mGlobalStyle.submitBtnStyle;
				$(".msubmit_btn .el-button").css({
					width: mSubmitBtnObj.width + "px",
					height: mSubmitBtnObj.height + "px",
					borderWidth: mSubmitBtnObj.borderWidth + "px",
					borderStyle: mSubmitBtnObj.borderStyle,
					borderColor: mSubmitBtnObj.borderColor,
					borderRadius: mSubmitBtnObj.borderRadius + "px",
					backgroundColor: mSubmitBtnObj.backgroundColor,
					fontSize: mSubmitBtnObj.fontSize + "px",
					fontWeight: mSubmitBtnObj.fontWight,
					fontStyle: mSubmitBtnObj.fontStyle,
					color: mSubmitBtnObj.color,
					textDecoration: mSubmitBtnObj.textDecoration,
				});
				$(".msubmit_btn").css({
					padding: mSubmitBtnObj.paddingTop + "px " + mSubmitBtnObj.paddingHorizontal + "px " + mSubmitBtnObj.paddingBottom + 'px',
					justifyContent: mSubmitBtnObj.textAlign == '居左'?'flex-start':mSubmitBtnObj.textAlign == '居中'?'center':'end',
				});
			})
		},
		// 表单提交
		formSubmit(){
			const _this = this;
			for (let i = 0; i < _this.list.length; i++) {
				const element = _this.list[i];
				for (let j = 0; j < element.children.length; j++) {
					const e = element.children[j];
					if(e.topic_required && (e.value === '' || e.value.length == 0)){
						_this.$message.error(`${e.label}不能为空！`);
						return false;
					}
				}
			}
			let jsonData = {
				_id: _this.formId,
				quesDate: _this.list,
				pcStyle:_this.globalStyle,
				mobileStyle:_this.mGlobalStyle,
			};
			let params = {
				verifyType:_this.verifyType,
				verificationCode:_this.form.gifCode,
				formObj:JSON.stringify(jsonData),
			}
			if(_this.verifyType == 1 && _this.form.gifCode == ''){
				_this.$message.error(`验证码不能为空！`);
				return false;
			}else{
				post('/formmodel/from_data_commit.jhtml',params).then(res => {
					if(res.code == 200){
						_this.$message({
							type: 'success',
							message: '提交成功！',
							onClose(){
								if(_this.identityType != 'user'){
									if(_this.urlType == 1){
										if(res.data.linkHtml != ''){
											window.location.href = res.data.linkHtml;
										}else{
											window.location.href = "https://bd.ruiyi126.com/";
										}
									}else{
										_this.formDateId = res.data.formDateId
										_this.$router.push({
											path:'/detail',
											query:{
												dataId:_this.formDateId,
												formId:_this.formId
											}
										})
										window.location.reload()
									}
								}
							}
						})
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}
		}
	}
};
</script>
<style lang="less" scoped>
	.mform-inner{
		width: 100%;
		height: 100%;
		border: 1px solid #ddd;
		overflow-y: auto;
		.mform-head{
			.img{
				img{
					width: 100%;
				}
			}
		}
		.mform-title{
			text-align: center;
		}
		.mform-item{
			position: relative;
			.cover{
				position: absolute;
				left: 0;top: 0;right:0;
				bottom: 0;z-index: 10;
			}
		}
		.yzm{
			margin: 0 20px 0 0;
		}
		.msubmit_btn{
			display: flex;
		}
		.limit-page{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 100%;
			padding: 0 0 20vh;
			.el-image{
				.el-icon-lock{
					font-size: 70px;
					color: #ccc;
				}
			}
			.pass-word{
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 5vh 0 0;
				.el-input{
					margin: 0 10px 0 0;
				}
			}
		}
		.cant-answer{
			height: 100%;
			.el-result{
				height: 90%;
			}
		}
	}
</style>